import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawer } from '@angular/material/sidenav';

import { AuthService, SettingsService, slideAnimation, WindowWrapper } from '@depot/@common';
import { NotificationMessageFormat, NotificationRepositoryService } from '@depot/@data';
import { ILocalNotificationSettings } from '@depot/custom';

@Component({
  selector: 'depot-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.scss'],
  animations: [
    slideAnimation
  ]
})
export class NotificationDrawerComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) notificationTray: MatDrawer;
  public messageFormat = NotificationMessageFormat;
  private faviconUrl = '';
  private favicon: HTMLLinkElement;
  constructor(
    public notificationRepo: NotificationRepositoryService,
    public authService: AuthService,
    private settingsService: SettingsService,
    private window: WindowWrapper,
    private destroyRef: DestroyRef,
  ) { }

  ngOnInit(): void {
    this.favicon = this.window.document.querySelector('link[rel=icon]');
    if (this.faviconUrl === '') {
      this.faviconUrl = this.favicon.href;
    }

    this.authService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => {
        if (x) {
          this.refreshNotifications();

        }
      });


    this.notificationRepo.notifications$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async x => {
        if (this.authService.user?.userName) {
          this.updateFavicon(x.length);

        } else {
          this.updateFavicon(0);
        }
      });
  }


  public showNotifications() {
    this.notificationTray.toggle();
  }

  public hideNotifications() {
    if (this.notificationTray.opened) {
      this.notificationTray.close();
    }
  }

  public refreshNotifications() {
    this.notificationRepo.getRelevantNotifications()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

  }
  private updateFavicon(count: number) {

    const faviconSize = 16;
    const canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;
    const context = canvas.getContext('2d');
    const img = document.createElement('img');

    if (this.favicon === null) {
      return;
    }
    img.src = this.favicon.href;

    img.onload = async () => {
      if (context === null) {
        return;
      }
      let notificationSettings: ILocalNotificationSettings;
      if (this.authService.user) {
        notificationSettings = await this.settingsService.notification();
      }
      // Draw Original Favicon as Background
      context.drawImage(img, 0, 0, faviconSize, faviconSize);
      const showLargeIcon = false;
      if (count > 0 && notificationSettings?.showInTitle && !showLargeIcon) {
        const iconOffset = faviconSize / 3;
        // Draw Notification Circle
        context.beginPath();
        context.arc(canvas.width - iconOffset, iconOffset, iconOffset, 0, 2 * Math.PI);
        context.fillStyle = '#F44336';
        // context.strokeStyle = '#000';
        context.fill();
        // context.stroke();

        // Draw Notification Number
        context.font = '11px Roboto, "Helvetica Neue", sans-serif';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = '#FFFFFF';
        context.fillText(count.toString(), canvas.width - iconOffset, iconOffset + 1);
        // Replace favicon
        this.favicon.href = canvas.toDataURL('image/png');
      } else if (count > 0 && notificationSettings?.showInTitle && showLargeIcon) {
        const iconOffset = faviconSize / 3;
        // Draw Notification Circle
        context.beginPath();
        context.arc(canvas.width - iconOffset - 1, iconOffset + 2, iconOffset + 1, 0, 2 * Math.PI);
        context.fillStyle = '#F44336';
        // context.strokeStyle = '#000';
        context.fill();
        // context.stroke();

        // Draw Notification Number
        context.font = '12px Roboto, "Helvetica Neue", sans-serif';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = '#FFFFFF';
        context.fillText(count.toString(), canvas.width - iconOffset - 1, iconOffset + 3);
        // Replace favicon
        this.favicon.href = canvas.toDataURL('image/png');
      } else {
        this.favicon.href = this.faviconUrl;
      }
    };
  }

  ngOnDestroy() {
  }
}
