import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { WindowWrapper } from '@depot/@common';

import { interval } from 'rxjs';

@Component({
  selector: 'depot-new-version-snackbar',
  template: `
  <div style="display: flex; justify-content: center; vertical-align: middle; line-height: 35px;">

       Page will reload in {{remainingSeconds}} seconds
       <span class="dp-flex-space"></span>

       <button mat-button (click)="reload()">
         Reload Now
        </button>
  </div>
  `,
  styles: [``]
})
export class NewVersionSnackbarComponent {
  public remainingSeconds = 60;
  constructor(
    private window: WindowWrapper,
    private destroyRef: DestroyRef,
  ) {
    interval(1000)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => {
        if (this.remainingSeconds > 0) {
          this.remainingSeconds--;
        }
      });
  }



  reload() {
    this.window.document.location.reload();
  }

}
