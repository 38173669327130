import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, DepotValidators, HelperService } from '@depot/@common';
import { IClientError } from '@depot/custom';

import { BehaviorSubject, catchError, finalize, of, Subscription } from 'rxjs';

@Component({
  selector: 'depot-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public form: UntypedFormGroup;
  private sub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private helper: HelperService,
    private fb: UntypedFormBuilder,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: [''],
      confirmPassword: [''],

    }, {
      validators: [DepotValidators.MatchPassword()]
    });


    // this.router.navigate(['/']);


  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  reset() {
    if (!this.form.valid) {
      return;
    }
    this.helper.IsGlobalBar$.set(true);
    this.globalErrors$.next([]);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const token = urlParams.get('token');
    const password = this.form.getRawValue().password;
    this.authService.resetPassword({ token, id, password })
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error resetting password', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalBar$.set(false))
      ).subscribe(x => {
        this.router.navigate(['/login']);
      });

  }

}
