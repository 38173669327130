<div>

  <mat-card appearance="outlined"
            class="login-card">
    <form (ngSubmit)="login()"
          [formGroup]="form">

      <mat-card-content>
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput
                 formControlName="userName"
                 type="text"
                 required>
          <mat-error [depot-form-error]="form"
                     errorControlName="userName">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput
                 required
                 formControlName="password"
                 type="password">
          <mat-error [depot-form-error]="form"
                     errorControlName="password">
          </mat-error>
        </mat-form-field>

        <div class="content">
          <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
        </div>
      </mat-card-content>

      <mat-card-actions class="tw-flex tw-justify-center tw-align-middle tw-flex-col">
        <button mat-raised-button
                type="submit"
                color="primary">Login</button>

        <button mat-flat-button
                type="button"
                (click)="resetPassword()">Reset Password</button>
      </mat-card-actions>



    </form>

  </mat-card>
</div>
