import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Route,
  Router
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import {
  AuthService,
  HelperService,
  routeAnimation,
  SettingsService,
  WindowWrapper
} from '@depot/@common';
import { NotificationRepositoryService } from '@depot/@data';
import { NewVersionDialogComponent, NewVersionSnackbarComponent } from '@depot/@main';
import { environment } from '@env';

import { BehaviorSubject, filter, first, interval, map, Subject, take, tap, timer } from 'rxjs';

import { groupBy, union } from 'underscore';

@Component({
  selector: 'depot-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [routeAnimation]
})
export class AppLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  public NavItems$ = new Subject<Route[]>();
  public PageIcon$ = new Subject();
  public PageTitle$ = new Subject();
  public HasNewVersion$ = new BehaviorSubject(false);
  isSideNavMini$ = new BehaviorSubject(false);
  @ViewChild(MatSidenav) drawer: MatSidenav;
  // public notifications$ = new BehaviorSubject<INotification[]>([]);
  isMobileOpen$ = new BehaviorSubject(false);
  public appVersion$ = new BehaviorSubject<string>(environment.version);
  // @HostBinding('class') componentCssClass;

  isMobile$ = this.breakpointObserver.observe([Breakpoints.XSmall])
    .pipe(
      map(result => {
        if (result.matches) {
          this.isSideNavMini$.next(false);
          this.isMobileOpen$.next(false);
        }
        return result.matches;
      })
    );

  public get isGtXs() {
    return this.breakpointObserver.isMatched([
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge]);
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private updates: SwUpdate,
    public helper: HelperService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public window: WindowWrapper,
    public router: Router,
    public overlayContainer: OverlayContainer,
    private titleService: Title,
    private settingsService: SettingsService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
    // private emailHub: EmailHubService,
    public notificationRepo: NotificationRepositoryService,
    private destroyRef: DestroyRef,
  ) {



  }
  public ngAfterViewInit() {

  }
  ngOnInit() {

    this.settingsService.navSize()
      .then(x => this.isSideNavMini$.next(x));
    if (this.updates.isEnabled) {
      // 1 hour
      this.updates.versionUpdates
        .pipe(
          filter(event => event.type === 'VERSION_READY'),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(x => {

          this.HasNewVersion$.next(true);
          this.dialog.open(NewVersionDialogComponent, {
            data: {},
            disableClose: true,
            maxHeight: '100vh'
          }).afterClosed()
            .pipe(first())
            .subscribe((shouldReload: boolean) => {
              if (shouldReload) {
                this.window.document.location.reload();

              } else {

                timer(240000)
                  .pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe(() => {
                    this.snackBar.openFromComponent(NewVersionSnackbarComponent, {
                      duration: 0,
                      verticalPosition: 'bottom',
                      panelClass: `warn-message`,
                    });
                  });

                // we set this to interval because if they have pending changes and they decide no to reload we need to try again
                interval(300000)
                  .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    take(3)
                  ).subscribe(() => {
                    this.helper.showMessage('Reloading page to get the new version', 'info', 0);
                    this.window.document.location.reload();
                  });

              }
            });
        });
      timer(0, 3600000)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async () => {
          if (await this.updates.checkForUpdate()) {
            await this.updates.activateUpdate();
          }
        });

    }
    this.settingsService.themeIsDark$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async isDark => {
        // const osThemeIsDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (isDark === true) {
          this.document.body.classList.remove('depot-light');
          this.document.body.parentElement.classList.remove('depot-light');
          this.document.body.classList.add('depot-dark');
          this.document.body.parentElement.classList.add('depot-dark');

        } else {
          this.document.body.classList.remove('depot-dark');
          this.document.body.parentElement.classList.remove('depot-dark');
          this.document.body.classList.add('depot-light');
          this.document.body.parentElement.classList.add('depot-light');

        }

      });



    this.authService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async user => {

        if (!!user) {
          this.determineNav();
          await this.settingsService.themeIsDark();

        } else {
          this.NavItems$.next(null);
          if (this.HasNewVersion$.getValue() === true) {
            this.window.document.location.reload();
          }
        }

      });



    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(event => {
          if (event instanceof NavigationStart) {
            this.helper.IsGlobalBar$.set(true);
          }
          if (event instanceof NavigationCancel) {
            this.helper.IsGlobalBar$.set(false);
          }
        }),
        // || event instanceof RoutesRecognized
        filter(event => event instanceof NavigationEnd))
      .subscribe(event => {

        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.onNavClick(route);
      });

  }

  ngOnDestroy() {
  }

  private determineNav() {

    if (this.window.self !== this.window.top) {
      // don't show the navigation if the page is in a iframe or embedded tag
      return;
    }
    // check if the route data is valid (text/permissions/etc.)


    // map the data to a readonly object used for navigation
    const routeData = this.router.config
      .filter(x => this.isValidRoute(x.data))
      .map(route => {
        const data = structuredClone(route.data);
        if (Array.isArray(route.data?.children)) {
          data.children = data.children.filter(x => this.isValidRoute(x));
        }
        return {
          path: route.path,
          data: data,
        };
      });
    const grouped = groupBy(routeData, x => x.path);

    const routes = [];
    for (const key of Object.keys(grouped)) {
      if (grouped[key].length > 1) {
        const firstItem = grouped[key][0];
        const remainingItems = grouped[key].slice(1).map(x => x.data.children);
        firstItem.data.children = union(firstItem.data.children, ...remainingItems);
        routes.push(firstItem);

      } else {
        const firstItem = grouped[key][0];
        if (firstItem.data.children?.length === 1) {
          firstItem.path = firstItem.data.children[0].path;
          firstItem.data.text = firstItem.data.children[0].text;
          firstItem.data.icon = firstItem.data.children[0].icon;
          firstItem.data.roles = firstItem.data.children[0].roles;
          firstItem.data.children = null;
        }
        routes.push(firstItem);

      }
    }
    this.NavItems$.next(routes);

  }

  private isValidRoute(data: Data) {
    if (!data || data.isNav === false) {
      return false;
    }
    if ((data.text ?? '').length === 0 && (data.children ?? []).length === 0) {
      return false;
    }
    return this.authService.isInRole(data.roles);
  }

  public onNavClick(navItem: ActivatedRoute) {
    if (!navItem || !navItem.routeConfig || !navItem.routeConfig.data) {
      this.PageIcon$.next('');
      this.PageTitle$.next('Depot Parts');
      this.titleService.setTitle('Depot Parts');
      return;
    }
    // const parentList = this.NavItems.filter(x => x.children && x.children.includes(navItem));
    // let parent = parentList.length > 0 ? parentList[0] : null;
    // navItem.active = true;
    if (navItem.component === undefined) {
      // navItem = navItem.parent.firstChild;
      // this.router.navigate([navItem.parent., navItem.path]);
    }
    // this.PageIcon$.next(navItem.data.icon || '');
    // if (this.isGtXs() && navItem.parent && navItem.parent.routeConfig) {
    //   this.PageTitle$.next(navItem.parent.routeConfig.data.text + ' > ');
    //   this.PageIcon$.next(navItem.parent.routeConfig.data.icon);
    // } else {
    const titleText = navItem.routeConfig.data.text || navItem.routeConfig.data.title || '';
    this.titleService.setTitle(titleText + ' | Depot Parts');
    this.PageTitle$.next(titleText);
    this.PageIcon$.next(navItem.routeConfig.data.icon);
    // }

    if (this.breakpointObserver.isMatched(Breakpoints.XSmall) && this.drawer) {
      this.drawer.close();
    }
  }

  async toggleNav(isMobile: boolean) {
    if (this.isGtXs) {
      const isMini = !this.isSideNavMini$.getValue();
      this.isSideNavMini$.next(isMini);
      // this.window.localStorage.setItem('navSize', <any>isMini);
      this.drawer.toggle(true);
      await this.settingsService.navSize(isMini);
    } else {
      this.isMobileOpen$.next(!this.isMobileOpen$.getValue());
      this.drawer?.open();
    }

  }



  public async logout() {
    if (await this.helper.confirmDialog('Are you sure you want to log out?')) {
      this.authService.logout().subscribe(x => {
        this.router.navigate(['/login']);
      });

    }
  }

  // public getRouterOutletState(outlet) {
  //   return outlet.isActivated ? outlet.activatedRoute : '';
  // }


}
