<depot-notification-drawer #notificationDrawer></depot-notification-drawer>
<mat-sidenav-container autosize
                       (backdropClick)="drawer.close()"
                       class="sidenav-container">

  <mat-sidenav #drawer
               *ngIf="(NavItems$ | async) as routes"
               class="sidenav mat-elevation-z1 print:tw-hidden"
               fixedInViewport="true"
               [disableClose]="true"
               [opened]="isGtXs"
               [ngClass]="(isSideNavMini$|async) ===true ? 'nav-mini':'nav-expanded'"
               [attr.role]="(isMobile$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isMobile$ | async) ? 'over':'side'">
    <mat-toolbar color="primary">
      <button type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="toggleNav()"
              *ngIf="!(isMobile$ | async)">
        <span mat-list-icon
              class="fa fa-bars nav-icon"></span>
      </button>
    </mat-toolbar>
    <mat-nav-list class="dp-nav-list tw-p-0">
        @for(nav of routes; track nav.path){
        <a mat-list-item
           [matTooltip]="nav.data['text']"
           matTooltipPosition="right"
           [matTooltipDisabled]="(isSideNavMini$|async)==false"
           [routerLink]="nav.path"
           [class.has-children]="nav.data['children'] && nav.data['children'].length > 0"
           routerLinkActive="active"
           class="dp-nav-item"
           *ngIf="nav.data['text'] || nav.data['icon']">
          <span matListItemAvatar
                class="dp-nav-icon"
                [ngClass]="nav.data['icon']"></span>
          <h5 matListItemTitle
              [class.hidden-text]="(isSideNavMini$|async) === true"
              class="sidenav-item">{{nav.data['text']}}</h5>

        </a>
        <span class="dp-child-group">
          <a mat-list-item
             *ngFor="let nav2 of nav.data.children"
             class="dp-nav-item dp-child-nav"
             [matTooltip]="nav2.text"
             matTooltipPosition="right"
             [matTooltipDisabled]="(isSideNavMini$|async)==false"
             [routerLink]="nav2.path"
             routerLinkActive="active">
            <span matListItemAvatar
                  class="dp-nav-icon"
                  [ngClass]="nav2.icon"></span>
            <h5 matListItemTitle
                [class.hidden-text]="(isSideNavMini$|async) === true"
                class="sidenav-item">{{nav2.text}}</h5>
          </a>
        </span>
      }


      <div class="dp-flex-space"></div>
      <!-- [disabled]="(notificationTray.openedChange | async)" -->
      <mat-list-item matTooltip="Notifications"
                     matTooltipPosition="right"
                     class="dp-nav-item"
                     [matTooltipDisabled]="(isSideNavMini$|async)==false"
                     (click)="notificationDrawer.showNotifications()">

        <span matListItemAvatar
              class="dp-nav-icon fa fa-bell"
              [ngClass]="(notificationRepo.connected$ | async) ? 'fa-bell':'fa-bell-slash'"
              matBadgePosition="below after"
              [matBadgeColor]="(notificationRepo.connected$ | async) ? 'primary':'warn'"
              matBadgeOverlap="true"
              [matBadgeHidden]="(notificationRepo.notifications$ | async).length === 0"
              [matBadge]="(notificationRepo.notifications$ | async).length"></span>
        <h5 matListItemTitle
            [class.hidden-text]="(isSideNavMini$|async) === true"
            class="sidenav-item">Notifications</h5>
      </mat-list-item>

      <mat-list-item matTooltip="Log Out"
                     matTooltipPosition="right"
                     class="dp-nav-item"
                     [matTooltipDisabled]="(isSideNavMini$|async)==false"
                     (click)="logout()">
        <span matListItemAvatar
              class="dp-nav-icon fa fa-sign-out-alt"></span>
        <h5 matListItemTitle
            [class.hidden-text]="(isSideNavMini$|async) === true"
            class="sidenav-item">Log Out</h5>
      </mat-list-item>
      <a mat-list-item
         matTooltip="My Settings"
         matTooltipPosition="right"
         class="dp-nav-item"
         [matTooltipDisabled]="(isSideNavMini$|async)==false"
         [routerLink]="['/settings']">
        <span matListItemAvatar
              class="dp-nav-icon fa fa-user-circle"></span>
        <h5 matListItemTitle
            [class.hidden-text]="(isSideNavMini$|async) === true"
            class="sidenav-item">My Settings</h5>
      </a>
      <div class="app-version tw-flex">
        @if((HasNewVersion$ | async) == true){
        <a href="javascript:void(0)"
           style="text-decoration: underline;"
           href="#"
           (click)="window.document.location.reload()">
          {{appVersion$ | async}}
        </a>
        }@else {
        {{appVersion$ | async}}
        }

        <div class="tw-flex-1 tw-box-border"></div>
      </div>
      <div class="app-environment tw-flex">
        {{ (authService.user$ | async)?.environmentAbbreviation }}
        <div class="tw-flex-1 tw-box-border"></div>
      </div>
    </mat-nav-list>
  </mat-sidenav>



  <mat-sidenav-content (click)="notificationDrawer.hideNotifications()">


    <mat-toolbar color="primary">
      <button type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="toggleNav()"
              *ngIf="(isMobile$ | async) && drawer">
        <span mat-list-icon
              class="fa fa-bars nav-icon"></span>
      </button>
      <span class="tw-pl-3">
        <i class="{{PageIcon$ | async}} fa-sm"></i>
        {{ PageTitle$ | async}}
      </span>
    </mat-toolbar>
    <mat-progress-bar *ngIf="helper?.IsGlobalBar$()"
                      color="accent"
                      mode="indeterminate"
                      class="dp-global-bar">
    </mat-progress-bar>
    <!-- [@routeAnimation]="o.isActivated ? o.activatedRoute : ''" -->
    <div class="main-content">
      <router-outlet #o="outlet"></router-outlet>
    </div>
    <mat-spinner *ngIf="helper?.IsGlobalSpinner$()"
                 [diameter]="55"
                 [strokeWidth]="5"
                 color="accent"
                 mode="indeterminate"
                 class="dp-spinner"></mat-spinner>
  </mat-sidenav-content>
</mat-sidenav-container>
