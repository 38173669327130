import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'depot-app-offline',
  template: `
  <div>
  <p>
    The app appears to be offline
    <br />
    <button mat-raised-button
            color="primary"
            [disabled]="isDisabled"
            (click)="reload()">Retry</button>
  </p>
  <!-- <br />
  <img
  (error)="noImage($event)"
   src="/app/assets/newman.gif" /> -->
    </div>
  `,
  styles: [`
  p {
    text-align: center;
    /* color: white; */
    font-size: 40px;
  }

  /* img{
    max-height: 403px;
    max-width: 361px;
    margin: auto;
    display: block;
  } */

  div{
    /* background-color: #1b1d44;
    background-image: url('/app/assets/error_404.png'); */
    /* background-size: cover; */
    /* background-repeat: no-repeat;
    background-position: center; */

  }
  `]

})
export class AppOfflineComponent implements OnInit {
  public isDisabled = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  public reload() {
    this.isDisabled = true;
    const returnPath = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/parts';
    this.router.navigate([returnPath]);
    setTimeout(() => {
      this.isDisabled = false;
    }, 5000);
  }
}
