<form *ngIf="form"
      (ngSubmit)="saveForm()"
      [formGroup]="form">

  <div class="content">
    <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
  </div>
  <div class="content tw-flex tw-flex-col tw-max-w-sm ">
    <mat-form-field class="readonly">
      <mat-label>Username</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="userName"
             readonly
             required
             type="text" />
      <mat-error [depot-form-error]="form"
                 errorControlName="userName">
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="email"
             required
             type="email" />
      <mat-error [depot-form-error]="form"
                 errorControlName="email">
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="firstName"
             required
             type="text" />
      <mat-error [depot-form-error]="form"
                 errorControlName="firstName">
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="lastName"
             required
             type="text" />
      <mat-error [depot-form-error]="form"
                 errorControlName="lastName">
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="password"
             type="password"
             autocomplete="off" />
      <mat-error [depot-form-error]="form"
                 errorControlName="password">
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password Confirmation</mat-label>
      <input matInput
             data-lpignore="true"
             formControlName="confirmPassword"
             type="password"
             autocomplete="off" />
      <mat-error [depot-form-error]="form"
                 errorControlName="confirmPassword">
      </mat-error>
    </mat-form-field>

    @if(timeZones$ | async; as timeZones){
    <mat-form-field>
      <mat-label>Time Zones</mat-label>
      <mat-select required
                  formControlName="timeZone">
        <mat-option *ngFor="let timeZone of timeZones"
                    [value]="timeZone.id">
          {{timeZone.displayName}}
        </mat-option>
      </mat-select>
      <mat-error [depot-form-error]="form"
                 errorControlName="timeZone">
      </mat-error>
    </mat-form-field>
    }

    @if(authService.isInRole$.SiteAdmin | async){
    <mat-form-field>
      <mat-label>Local Theme</mat-label>
      <mat-select [value]="settingsService.themeIsDark$ | async"
                  (selectionChange)="settingsService.themeIsDark($event.value)">
        <mat-option [value]="false">
          Light
        </mat-option>
        <mat-option [value]="true">
          Dark
        </mat-option>
      </mat-select>

    </mat-form-field>
    }
    <mat-slide-toggle [checked]="notificationSettings.showNotification"
                      (change)="notificationPermissionToggle($event)">
      Show Notification
    </mat-slide-toggle>

    <mat-slide-toggle *ngIf="notificationSettings.showNotification"
                      [(checked)]="notificationSettings.playSound"
                      (change)="notificationSettings.playSound = $event.checked; persistSettings()">
      Play Sound
    </mat-slide-toggle>

    <mat-slide-toggle [(checked)]="notificationSettings.showInTitle"
                      (change)="notificationSettings.showInTitle = $event.checked; persistSettings()">
      Show Title Badge
    </mat-slide-toggle>

    <button mat-raised-button
            color="accent"
            type="button"
            (click)="testNotification()">
      <!-- <span class="fa-stack">
          <i class="fa fa-bell fa-stack-2x"></i>
          <i class="fa fa-plus fa-stack-1x"
             style="color: var(--dp-color-primary)"></i>
        </span> -->
      Test Notification</button>
  </div>

  <div class="dp-top-fab-container">

    <button type="submit"
            mat-mini-fab
            matTooltip="Save User"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-save"></span>
    </button>
  </div>
</form>
