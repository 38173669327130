import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'depot-new-version-dialog',
  template: `
  <h1 mat-dialog-title>New version available</h1>
  <div mat-dialog-content>
  Please save your changes and refresh the page.  The page will automatically
    refresh in 5 minutes
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button  (click)="dialogRef.close(false)">Wait</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">Reload Now</button>
  </div>
  `,
  styles: [``]
})
export class NewVersionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewVersionDialogComponent>,

  ) { }

  ngOnInit() {
  }

}
