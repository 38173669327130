import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, HelperService } from '@depot/@common';
import { PromptDialogComponent } from '@depot/@components';
import { IClientError } from '@depot/custom';

import { BehaviorSubject, catchError, finalize, lastValueFrom, of, Subscription } from 'rxjs';

@Component({
  selector: 'depot-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public form: UntypedFormGroup;
  private sub: Subscription;
  private returnUrl = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helper: HelperService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      userName: [''],
      password: ['']

    });
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/parts';
    if (this.authService.user === undefined) {
      this.authService.getUserData().subscribe(user => {
        if (!!user) {
          this.router.navigate([this.returnUrl]);
        }
      });
    }
    setTimeout(() => {
      this.helper.IsGlobalBar$.set(false);
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public login() {
    if (!this.form.valid) {
      return;
    }
    this.helper.IsGlobalBar$.set(true);
    this.globalErrors$.next([]);

    this.authService
      .login(this.form.controls.userName.value, this.form.controls.password.value)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error logging in', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalBar$.set(false)))
      .subscribe(x => {
        if (!x) {
          this.helper.showMessage('Could not log in', 'error');
        } else {
          this.router.navigate([this.returnUrl]);
        }
      });

  }

  async resetPassword() {
    this.helper.IsGlobalBar$.set(true);
    this.globalErrors$.next([]);

    const email = await lastValueFrom(this.dialog
      .open(PromptDialogComponent, {
        data: {
          message: 'Enter the e-mail associated to your account so we can send you a reset link.',
          title: 'Reset Password',
          placeholder: 'Email'
        },
      })
      .afterClosed());
    if (email !== null) {
      this.authService.sendResetPasswordEmail(email)
        .pipe(
          catchError((error: IClientError) => {
            this.helper.mapErrors(error, null, this.form, this.globalErrors$);
            this.helper.logger.error('Error requesting pasword reset', error);
            return of();
          }),
          finalize(() => this.helper.IsGlobalBar$.set(false))
        )
        .subscribe(() => {
          this.helper.showMessage('Reset link sent to your email address', 'success');
        });
    } else {
      this.helper.IsGlobalBar$.set(false);

    }

    return false;
  }

}
