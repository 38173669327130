<div>
  <mat-card appearance="outlined"
            class="reset-card">

    <form *ngIf="form"
          (ngSubmit)="reset()"
          [formGroup]="form">

      <div class="content">
        <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
      </div>
      <div>

        <div>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput
                   data-lpignore="true"
                   formControlName="password"
                   type="password"
                   required
                   autocomplete="off" />
            <mat-error [depot-form-error]="form"
                       errorControlName="password">
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput
                   data-lpignore="true"
                   formControlName="confirmPassword"
                   type="password"
                   required
                   autocomplete="off" />
            <mat-error [depot-form-error]="form"
                       errorControlName="confirmPassword">
            </mat-error>
          </mat-form-field>
        </div>
        <br />
        <button mat-raised-button
                type="submit"
                [disabled]="!form.valid"
                color="primary">Reset Password</button>


      </div>
    </form>
  </mat-card>
</div>
