import { NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  Route,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';

import { RoleGuard } from '@depot/@common';
import { DefaultChildComponent } from '@depot/@components';
import {
  AppOfflineComponent,
  LoginComponent,
  NotFoundComponent,
  ResetPasswordComponent,
  UserSettingComponent,
} from '@depot/@main';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/parts',
    pathMatch: 'full'
  },
  {
    path: 'parts',
    loadChildren: () => import('@depot/parts/parts.module').then(x => x.PartsModule),
    data: {
      text: 'Parts',
      icon: 'fa fa-boxes',
      roles: [RoleGuard.Admin]
    },
  },
  {
    path: 'putaway',
    loadChildren: () => import('@depot/putaway/putaway.module').then(x => x.PutawayModule),
    data: {
      text: 'Put Away',
      icon: 'fa fa-dolly-flatbed',
      roles: [RoleGuard.Admin]
    },
  },
  // {
  //   path: 'scrap-report',
  //   loadChildren: () => import('@depot/scrap-report/scrap-report.module').then(x => x.ScrapReportModule),
  //   data: {
  //     text: 'Scrap Report',
  //     icon: 'fa fa-recycle',
  //     roles: [RoleGuard.ScrapAdmin]
  //   },
  // },

  {
    path: 'returns',
    canActivate: [RoleGuard],
    component: DefaultChildComponent,
    children: [
      {
        path: 'request',
        loadChildren: () => import('@depot/returns/request/returns-request.module').then(x => x.ReturnsRequestModule),
      },
      {
        path: 'pickup',
        loadChildren: () => import('@depot/returns/pickup/returns-pickup.module').then(x => x.ReturnsPickupModule),
      },
      {
        path: 'incoming',
        loadChildren: () => import('@depot/returns/incoming/returns-incoming.module').then(x => x.ReturnIncomingModule),
      },
      // {
      //   path: 'incoming-shipment',
      //   loadChildren: () => import('@depot/returns/incoming-shipment/returns-incoming-shipment.module').then(x => x.ReturnIncomingShipmentModule),
      // },
      {
        path: 'weight-ticket',
        loadChildren: () => import('@depot/returns/weight-ticket/returns-weight-ticket.module').then(x => x.ReturnWeightTicketModule),
      },
      {
        path: 'scrap',
        loadChildren: () => import('@depot/returns/scrap/returns-scrap.module').then(x => x.ReturnsScrapModule),
      },
    ],
    data: {
      text: 'Dealer Returns',
      icon: 'fa fa-truck-moving',
      roles: [RoleGuard.PickupAdmin, RoleGuard.IncomingAdmin, RoleGuard.WeightTicketAdmin, RoleGuard.ScrapAdmin],
      children: [
        // {
        //   path: 'returns/request',
        //   text: 'Request',
        //   icon: 'fa fa-headset',
        //   roles: [RoleGuard.PickupAdmin]
        // },
        {
          path: 'returns/pickup',
          text: 'Pickup',
          icon: 'fa fa-shipping-fast',
          roles: [RoleGuard.PickupAdmin]
        },
        {
          path: 'returns/incoming',
          text: 'Incoming',
          icon: 'fa fa-pallet',
          roles: [RoleGuard.IncomingAdmin]
        },
        // {
        //   path: 'returns/incoming-shipment',
        //   text: 'Incoming',
        //   icon: 'fa fa-pallet',
        //   roles: [RoleGuard.SiteAdmin]
        // },
        {
          path: 'returns/weight-ticket',
          text: 'Weight Ticket',
          icon: 'fa fa-weight-hanging',
          roles: [RoleGuard.WeightTicketAdmin]
        },
        {
          path: 'returns/scrap',
          text: 'Scrap',
          icon: 'fa fa-recycle',
          roles: [RoleGuard.ScrapAdmin]
        },
      ]
    },

  },

  // {
  //   path: 'returns',
  //   loadChildren: () => import('@depot/returns/pickup/returns-pickup.module').then(x => x.ReturnsPickupModule),
  //   data: {
  //     text: 'Dealer Returns',
  //     icon: 'fa fa-truck-moving',
  //     roles: [RoleGuard.PickupAdmin],
  //     children: [
  //       {
  //         path: 'returns/pickup',
  //         text: 'Pickup',
  //         icon: 'fa fa-shipping-fast',
  //         roles: [RoleGuard.PickupAdmin]
  //       },
  //     ]
  //   },

  // },
  // {
  //   path: 'returns',
  //   loadChildren: () => import('@depot/returns/incoming/returns-incoming.module').then(x => x.ReturnIncomingModule),
  //   data: {
  //     text: 'Dealer Returns',
  //     icon: 'fa fa-truck-moving',
  //     roles: [RoleGuard.IncomingAdmin],
  //     children: [
  //       {
  //         path: 'returns/incoming',
  //         text: 'Pickup',
  //         icon: 'fa fa-pallet',
  //         roles: [RoleGuard.IncomingAdmin]
  //       },
  //     ]
  //   },

  // },
  // {
  //   path: 'returns',
  //   loadChildren: () => import('@depot/returns/scrap/returns-scrap.module').then(x => x.ReturnsScrapModule),
  //   data: {
  //     text: 'Dealer Returns',
  //     icon: 'fa fa-truck-moving',
  //     roles: [RoleGuard.ScrapAdmin],
  //     children: [
  //       {
  //         path: 'returns/scrap',
  //         text: 'Scrap',
  //         icon: 'fa fa-recycle',
  //         roles: [RoleGuard.ScrapAdmin]
  //       },
  //     ]
  //   },

  // },

  {
    path: 'audit',
    loadChildren: () => import('@depot/audit/audit.module').then(x => x.AuditModule),
    data: {
      text: 'Audit',
      icon: 'fa fa-clipboard-check',
      roles: [RoleGuard.Admin]
    },
  },

  {
    path: 'price-book',
    canActivate: [RoleGuard],
    component: DefaultChildComponent,
    children: [
      {
        path: 'line',
        loadChildren: () => import('@depot/price-book/line/price-book-line.module').then(x => x.PriceBookLineModule),
      },
      {
        path: 'page',
        loadChildren: () => import('@depot/price-book/page/price-book-page.module').then(x => x.PriceBookPageModule),
      },
    ],
    data: {
      text: 'Price Books',
      icon: 'fa fa-money-check-alt',
      roles: [RoleGuard.Admin],
      children: [
        {
          path: 'price-book/line',
          text: 'Lines',
          icon: 'fa fa-money-check',
          roles: [RoleGuard.Admin]
        },
        {
          path: 'price-book/page',
          text: 'Pages',
          icon: 'fa fa-book',
          roles: [RoleGuard.Admin]
        },
      ]
    },

  },

  // {
  //   path: 'price-book',
  //   loadChildren: () => import('@depot/price-book/price-book-line.module').then(x => x.PriceBookModule),
  //   data: {
  //     text: 'Price Book',
  //     icon: 'fa fa-money-check-alt',
  //     roles: [RoleGuard.Admin]
  //   },
  // },
  {
    path: 'order',
    loadChildren: () => import('@depot/order/order.module').then(x => x.OrderModule),
    data: {
      text: 'Order',
      icon: 'fa fa-file-invoice-dollar',
      roles: [
        RoleGuard.OrderManager,
      ]
    },
  },
  {
    path: 'pricing',
    loadChildren: () => import('@depot/pricing/pricing.module').then(x => x.PricingModule),
    data: {
      text: 'Pricing',
      icon: 'fa fa-money-check',
      roles: [RoleGuard.Admin]
    },
  },
  {
    path: 'message',
    loadChildren: () => import('@depot/message/message.module').then(x => x.MessageModule),
    data: {
      text: 'Message',
      icon: 'fa fa-envelope-open-text',
      roles: [RoleGuard.Admin]
    },
  },
  {
    path: 'dealer',
    loadChildren: () => import('@depot/dealer/dealer.module').then(x => x.DealerModule),
    data: {
      text: 'Dealer',
      icon: 'fa fa-user-friends',
      roles: [RoleGuard.Admin]
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('@depot/admin/admin.module').then(x => x.AdminModule),
    data: {
      text: 'Admin',
      icon: 'fa fa-user-shield',
      roles: [RoleGuard.SiteAdmin, RoleGuard.UserAdmin],
      children: [
        {
          path: 'admin/users',
          text: 'Users',
          icon: 'fa fa-user-cog',
          roles: [RoleGuard.SiteAdmin, RoleGuard.UserAdmin]
        },
        {
          path: 'admin/data-load',
          text: 'Data Load',
          icon: 'fa fa-database',
          roles: [RoleGuard.SiteAdmin, RoleGuard.UserAdmin]

        },
        {
          path: 'admin/dashboard',
          text: 'Dashboard',
          icon: 'fa fa-chart-line',
          roles: [RoleGuard.SiteAdmin]
        },
        {
          path: 'admin/logs',
          text: 'Logs',
          icon: 'fa fa-columns',
          roles: [RoleGuard.SiteAdmin]
        },
        {
          path: 'admin/email-logs',
          text: 'Email Logs',
          icon: 'fa fa-envelope',
          roles: [RoleGuard.SiteAdmin]
        },


      ]
    },

  },

  {
    path: 'settings',
    component: UserSettingComponent,
    canActivate: [RoleGuard],
    data: {
      isNav: false,
      text: 'My Settings',
      icon: 'fa fa-user-circle',
      roles: [RoleGuard.Admin]
    }
  },


  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    }
  },

  {
    path: 'app-offline',
    component: AppOfflineComponent,
    data: {
      title: 'App Offline'
    }
  },

  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Page Not Found',
      roles: [RoleGuard.Admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
