import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@depot/@main/app.module';
import { environment } from '@env';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => { };
  }

}
platformBrowserDynamic().bootstrapModule(AppModule, [
  {
    // defaultEncapsulation: ViewEncapsulation.None,
  }
]).then(() => {
  if ('serviceWorker' in navigator && environment.production) {
    navigator.serviceWorker.register('./ngsw-worker.js',
      {
        // scope: environment.app_endpoint
      });
  }
})
  .catch(err => console.error(err));
