import { Component, OnInit } from '@angular/core';

import { HelperService } from '@depot/@common';

@Component({
  selector: 'depot-not-found',
  template: `
  <div>
    <p>
      The page you are looking for does not exist.
    </p>
  </div>
  `,
  styles: [`
  p {
    text-align: center;
    /* color: white; */
    font-size: 40px;
    /* text-shadow: 3px 3px 3px #000; */
  }

  /* img{
    max-height: 403px;
    max-width: 361px;
    margin: auto;
    display: block;
  } */

  /* div{
    background-color: #1b1d44;
    background-image: url('/app/assets/error_404.png');
    background-repeat: no-repeat;
    background-position: center;

  } */
  `]

})
export class NotFoundComponent implements OnInit {

  constructor(helper: HelperService) {
    helper.IsGlobalBar$.set(false);
  }

  ngOnInit() {

  }
  // public noImage(event: Event) {
  //   (event.target as HTMLImageElement).style.display = 'none';
  // }
}
